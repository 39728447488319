import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeviceService } from '@helpers/device.service';
import { VIEW_MANAGER_URI } from 'const';
import { Observable, switchMap } from 'rxjs';
import * as models from '../models';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import {
  getHotDealsProperty,
  getMoreHotDealsProperty,
  getMoreTop10Property,
  getTop10RealProperty
} from '@app/ngrx/real-property-data/actions';
import {
  getHotDealsPropertyData, getHotDealsPropertyLoading, getHotDealsPropertyVisibleData,
  getTopRealPropertyData, getTopRealPropertyVisibleData,
  getTopTealPropertyLoading
} from "@app/ngrx/real-property-data/states/real-property-data-getters.state";

export interface VideoReviewType {
  title: string;
  src: string;
  id: number;
  price?: any;
  photoUuid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  top10ViewReview$: Observable<VideoReviewType[]> = this.store.select(getTopRealPropertyData);
  top10ViewReviewLoading$: Observable<boolean> = this.store.select(getTopTealPropertyLoading);
  visibleTop10Review$: Observable<VideoReviewType[]> = this.store.select(getTopRealPropertyVisibleData);

  hotDeals$: Observable<VideoReviewType[]> = this.store.select(getHotDealsPropertyData);
  hotDealsLoading$: Observable<boolean> = this.store.select(getHotDealsPropertyLoading);
  visibleHotDeals$: Observable<VideoReviewType[]> = this.store.select(getHotDealsPropertyVisibleData);

  constructor(
    @Inject(VIEW_MANAGER_URI) private viewManagerURI: string,
    private _http: HttpClient,
    private _deviceService: DeviceService,
    private store: Store<AppState>,
  ) {}

  public getTop10RealPropertyDispatch() {
    this.store.dispatch(getTop10RealProperty());
  }

  public getMoreTop10Property(size: number) {
    this.store.dispatch(getMoreTop10Property({ size }));
  }

  public getHotDealsRealProperty() {
    this.store.dispatch(getHotDealsProperty());
  }

  public getMoreHotDeals(size: number) {
    this.store.dispatch(getMoreHotDealsProperty({ size }));
  }

  public getTopRealProperty(filter: models.GetTopRealPropertyParams): Observable<models.RealPropertySellApplicationResp> {
    return this._deviceService.getDeviceUUID().pipe(
      switchMap((uuid) =>
        this._http.post(`${this.viewManagerURI}/open-api/realProperty/getTopRealProperty`, {
          ...filter,
          deviceUuid: uuid,
        }),
      ),
    );
  }

  public getCurrency() {
    return this._http.get('https://v6.exchangerate-api.com/v6/e8a1ec648c55626754ff7254/latest/KZT');
  }

  public getWeather(city: string) {
    const API_KEY = '7fbc095131994d76be893435253101';
    return this._http.get(`https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${city}&lang=ru`);
  }
}
