import { createAction, props } from '@ngrx/store';
import * as models from '@modules/real-property-data/models';
import { VideoReviewType } from '@modules/real-property-data/services';

type TopProperty = 'top10ViewReview' | 'hotDeals' | 'top1RoomFlats' | 'top2RoomFlats' | 'top3RoomFlats' | 'topDecreasePrice';

const REALPROPERTY = 'REAL PROPERTY';

export const getRealPropertyData = createAction(`[${REALPROPERTY}] Get Data`, props<{ filter: any }>());
export const getRealPropertyDataSuccess = createAction(`[${REALPROPERTY}] Get Data Success`, props<{ data: any, totalCountData: number }>());
export const getRealPropertyDataFailure = createAction(`[${REALPROPERTY}] Get Data Failure`, props<{ errorMessage: string }>());

export const updateRealPropertyItemSuccess = createAction(`[${REALPROPERTY}] Update Item`, props<{ item: any }>());

export const getRealPropertyDataCount = createAction(`[${REALPROPERTY}] Get Count`, props<{ filter: any }>());
export const getRealPropertyDataCountSuccess = createAction(`[${REALPROPERTY}] Get Count Success`, props<{ count: number }>());
export const getRealPropertyDataCountFailure = createAction(`[${REALPROPERTY}] Get Count Failure`, props<{ errorMessage: string }>());

export const getTop10RealProperty = createAction(`[${REALPROPERTY}] Get Top 10`);
export const getTop10RealPropertySuccess = createAction(`[${REALPROPERTY}] Get Top 10 Success`, props<{ data: VideoReviewType[] }>());
export const getTop10RealPropertyFailure = createAction(`[${REALPROPERTY}] Get Top 10 Failure`, props<{ errorMessage: string }>());

export const getMoreTop10Property = createAction(`[${REALPROPERTY}] Get More top 10`, props<{ size: number }>());

export const getHotDealsProperty = createAction(`[${REALPROPERTY}] Get Hot Deals`);
export const getHotDealsPropertySuccess = createAction(`[${REALPROPERTY}] Get Hot Deals Success`, props<{ data: VideoReviewType[] }>());
export const getHotDealsPropertyFailure = createAction(`[${REALPROPERTY}] Get Hot Deals Failure`, props<{ errorMessage: string }>());

export const getMoreHotDealsProperty = createAction(`[${REALPROPERTY}] Get More Hot Deals`, props<{ size: number }>());

