import { IRealPropertyDataState } from '@app/ngrx/real-property-data/states/real-property-data.state';
import { createReducer, on } from '@ngrx/store';

import * as realPropertyActions from '../actions';
import { createEntityAdapter } from '@ngrx/entity';

export const adapter = createEntityAdapter();

export const getInitialRealPropertyData: IRealPropertyDataState = adapter.getInitialState({
  loaded: false,
  loading: false,
  totalCountFilter: 0,
  totalCountData: 0,
  top10VideoReview: [],
  visibleTop10: [],
  top10Loading: false,
  top10Loaded: false,
  hotDeals: [],
  visibleHotDeals: [],
  hotLoading: false,
  hotLoaded: false,
});

export const realPropertyDataReducer = createReducer(
  getInitialRealPropertyData,
  on(realPropertyActions.getRealPropertyDataCount, (state) => ({ ...state, loading: true, loaded: false })),
  on(realPropertyActions.getRealPropertyDataCountSuccess, (state, { count }) => {
    return { ...state, loading: false, loaded: true, totalCountFilter: count };
  }),
  on(realPropertyActions.updateRealPropertyItemSuccess, (state, { item }) => {
    return adapter.upsertOne(item, { ...state });
  }),
  on(realPropertyActions.getRealPropertyDataSuccess, (state, { data, totalCountData }) => {
    return adapter.setAll(data, { ...state, loading: false, loaded: true, totalCountData });
  }),
  on(realPropertyActions.getTop10RealProperty, (state) => ({ ...state, top10Loading: true })),
  on(realPropertyActions.getTop10RealPropertySuccess, (state, { data }) => {
    return { ...state, top10VideoReview: data, top10Loading: false, top10Loaded: true, visibleTop10: data.slice(0, 10) };
  }),
  on(realPropertyActions.getHotDealsProperty, (state) => ({ ...state, hotLoading: true })),
  on(realPropertyActions.getHotDealsPropertySuccess, (state, { data }) => {
    return { ...state, hotDeals: data, hotLoading: false, hotLoaded: true, visibleHotDeals: data.slice(0, 10) };
  }),
  on(realPropertyActions.getMoreTop10Property, (state, { size }) => {
    console.log(state.visibleTop10.length + size);
    return { ...state, visibleTop10: state.top10VideoReview.slice(0, state.visibleTop10.length + size) };
  }),
  on(realPropertyActions.getMoreHotDealsProperty, (state, { size }) => {
    return { ...state, visibleHotDeals: state.hotDeals.slice(0, state.visibleHotDeals.length + size) };
  }),
);
