import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RealPropertyService } from '@modules/real-property-details';

import { exhaustMap, of, switchMap } from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';

import * as realPropertyActions from '../actions';
import { HomeService, VideoReviewType } from '@modules/real-property-data/services';
import * as models from '@modules/real-property-data/models';
import { AddressService } from '@shared/services';

@Injectable()
export class RealPropertyDataEffects {

  getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getRealPropertyData),
      switchMap(({ filter }) => {
        return this._realPropertyService.getRealPropertyList(filter).pipe(
          map((data) => {
            return { count: data.total, data: this._realPropertyService.serializeRealPropertyData(data.data.data) }
          }),
          map(({ count, data }) => {
            return realPropertyActions.getRealPropertyDataSuccess({ data: data, totalCountData: count });
          }),
          catchError((error) => of(realPropertyActions.getRealPropertyDataFailure({ errorMessage: error.message })))
        );
      }),
    );
  });

  getCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getRealPropertyDataCount),
      exhaustMap(({ filter }) => {
        return this._realPropertyService.getRealPropertyCount(filter).pipe(
          map((count) => {
            return realPropertyActions.getRealPropertyDataCountSuccess({ count });
          }),
          catchError((error) => of(realPropertyActions.getRealPropertyDataCountFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  getTop$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getTop10RealProperty),
      switchMap(() => {
        return this._addressService.currentCity$.pipe(
          filter((r) => !!r),
          switchMap((city) => {
            return this._homeService.getTopRealProperty({ addressCodes: [city.code], flags: [101] }).pipe(
              map((data: any) => {
                return realPropertyActions.getTop10RealPropertySuccess({ data: this.transformToVideoType(data) });
              }),
              catchError((err) => of(realPropertyActions.getTop10RealPropertyFailure({ errorMessage: err.message }))),
            );
          }),
        );
      }),
    );
  });

  getHot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getHotDealsProperty),
      switchMap(() => {
        return this._addressService.currentCity$.pipe(
          filter((r) => !!r),
          switchMap((city) => {
            return this._homeService.getTopRealProperty({ addressCodes: [city.code], flags: [102] }).pipe(
              map((data: any) => {
                return realPropertyActions.getHotDealsPropertySuccess({ data: this.transformToVideoType(data) });
              }),
              catchError((err) => of(realPropertyActions.getHotDealsPropertyFailure({ errorMessage: err.message }))),
            );
          }),
        );
      }),
    );
  });

  transformToVideoType(data: any[]): VideoReviewType[] {
    return data.map((item: any) => ({
      id: item.applicationId,
      title: item.residentialComplexName ? `ЖК ${item.residentialComplexName}` : '',
      src: item.virtualTourImageIdList.length ? item.virtualTourImageIdList[0] : '',
      price: item.objectPrice,
      photoUuid: item.photoIdList[0],
    }));
  }

  constructor(
    private actions$: Actions,
    private _realPropertyService: RealPropertyService,
    private _homeService: HomeService,
    private _addressService: AddressService,
  ) {}
}
